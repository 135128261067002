/* src/functions/teams.ts */
import { TeamView } from "@/interfaces/teams";
import { Preferences } from "@capacitor/preferences";

export async function getCurrentTeam(): Promise<TeamView | null> {
  const { value } = await Preferences.get({ key: "currentTeam" });
  if (value !== null) {
    return JSON.parse(value);
  } else {
    return null;
  }
}

export async function setCurrentTeam(team: TeamView): Promise<void> {
  await Preferences.set({
    key: "currentTeam",
    value: JSON.stringify(team),
  });
}
