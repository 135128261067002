/* src/stores/auth.ts 
 */
import { defineStore } from 'pinia'

type UserState = {
  accessToken: string|null,
  user: any,
  ready: boolean,
  claims: Record<any,any>|null,
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    ready: false,
    claims: null,
  } as UserState ),
  getters:{
    token: async (state): Promise<string|null> => {
      if (!state.user) {return null}
      try {
        const idToken = await state.user.getIdTokenResult(true)
        if (idToken){
          return idToken.token
        } else{
          return null
        }
      } catch(err){
        // eslint-disable-next-line
        console.log('Error while getting user access token', err)
        return null
      }
    },
  },
  actions:{
    async onUserChange(user: any) {
      this.user = user
      if (!this.user) {
        this.claims = null
        return
      }
      try {
        const idToken = await this.user.getIdTokenResult(true)
        if (idToken){
          this.claims = idToken.claims
        } else{
          this.claims = null
        }
      } catch(err){
        // eslint-disable-next-line
        console.log('Error while getting user access token', err)
      }
    },
    setReady(){
      this.ready = true
    },
    notReady(){
      this.ready = false
    },
  }

})
