import { createRouter, createWebHistory } from "@ionic/vue-router";
import { alertController } from "@ionic/vue";
import { RouteRecordRaw } from "vue-router";
import { Capacitor } from "@capacitor/core";
import { useAppAuth } from "@/plugins";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/homepage",
  },
  {
    path: "/homepage",
    component: () => import("@/views/tabs/HomepageTab.vue"),
    name: "Homepage",
  },
  {
    path: "/about",
    component: () => import("@/views/AboutView.vue"),
    name: "About",
  },
  {
    path: "/overview",
    meta: {
      requiresMember: true,
    },
    component: () => import("@/views/tabs/TabOverview.vue"),
    name: "Overview",
  },
  {
    path: "/sites",
    meta: {
      requiresMember: true,
    },
    component: () => import("@/views/tabs/TabSites.vue"),
    name: "SiteList",
  },
  {
    path: "/orders",
    component: () => import("@/views/tabs/TabOrders.vue"),
    meta: {
      requiresMember: true,
    },
    name: "OrderList",
  },
  {
    path: "/fulfillment",
    component: () => import("@/views/tabs/TabFulfillment.vue"),
    meta: {
      requiresMember: true,
    },
  },
  {
    path: "/user_profile",
    component: () => import("@/views/tabs/TabUserProfile.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "UserProfile",
  },
  {
    path: "/login",
    component: () => {
      if (Capacitor.isNativePlatform()) {
        return import("@/views/NativeLoginView.vue");
      } else {
        return import("@/views/LoginView.vue");
      }
    },
    name: "Login",
  },
  {
    path: "/orders/:orderId",
    component: () => import("@/views/OrderDetailView.vue"),
    props: true,
    name: "OrderDetail",
    meta: {
      requiresMember: true,
    },
  },
  {
    path: "/sites/:siteId",
    component: () => import("@/views/SiteDetailView.vue"),
    props: true,
    name: "SiteDetail",
    meta: {
      requiresMember: true,
    },
  },
  {
    path: "/teams",
    component: () => import("@/views/UserTeamsView.vue"),
    name: "UserTeams",
    meta: {
      requiresMember: true,
    },
  },
  {
    path: "/teams/:teamId",
    component: () => import("@/views/TeamDetailView.vue"),
    name: "TeamDetail",
    props: true,
    meta: {
      requiresMember: true,
    },
  },
  {
    path: "/team-fulfillments",
    name: "TeamFulfillments",
    component: () => import("@/views/TeamFulfillments.vue"),
    meta: {
      requiresMember: true,
    },
  },
  {
    path: "/fulfillment-services",
    name: "FulfillmentServices",
    component: () => import("@/views/FulfillmentServices.vue"),
    meta: {
      requiresMember: true,
    },
  },
  {
    path: "/fulfillment-services/:ffServiceId",
    name: "FulfillmentServiceDetail",
    component: () => import("@/views/FulfillmentServiceDetail.vue"),
    meta: {
      requiresMember: true,
    },
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function isLoggedIn(): boolean {
  const { currentUser } = useAppAuth();
  if (currentUser.value === null) {
    return false;
  } else {
    return true;
  }
}

async function isMember(): Promise<boolean> {
  if (!isLoggedIn()) {
    return false;
  }
  const { getUserClaims } = useAppAuth();
  const userClaims = await getUserClaims();
  if (userClaims === null) {
    return false;
  }
  const isMember = userClaims?.member || false;
  if (isMember) {
    return true;
  } else {
    return false;
  }
}

router.beforeEach(async (to, _, next) => {
  if (to.meta.requiresMember) {
    const member = await isMember();
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else if (isLoggedIn() && member === false) {
      const alert = await alertController.create({
        header: "Unauthorized",
        message: "You are not allow to view this",
        buttons: ["OK"],
      });
      await alert.present();
      next("/");
    } else {
      next();
    }
  } else if (to.meta.requiresAuth) {
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
