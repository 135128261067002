/* src/firebase.service.ts */

import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { getApps, initializeApp } from "firebase/app";
import {
  indexedDBLocalPersistence,
  initializeAuth,
  User,
  GoogleAuthProvider,
  getAuth,
  signInWithCredential,
  connectAuthEmulator,
} from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBKZjqew3HDwsDFqjU30yILiFQQZHOTbUw",
  authDomain: "hyper-hub.firebaseapp.com",
  projectId: "hyper-hub",
  storageBucket: "hyper-hub.appspot.com",
  messagingSenderId: "657665457269",
  appId: "1:657665457269:web:ab258b45696948f51acc3c",
};

export const user = ref<User | null>(null);

export const firebaseApp =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export const firebaseAuth = initializeAuth(firebaseApp, {
  persistence: indexedDBLocalPersistence,
});

export const firestoreDb = getFirestore(firebaseApp);

// Emulator connection
const useEmulator = process.env.VUE_APP_USE_FB_EMULATOR;
if (process.env.NODE_ENV === "development") {
  console.log("DEVELOPMENT ENVIRONMENT");
  if (useEmulator === "true") {
    console.log("Using Firebase Emulator");
    // Auth Emulator
    try {
      const authEmuPort = process.env.VUE_APP_AUTH_EMULATOR_PORT || 9099;
      connectAuthEmulator(firebaseAuth, `http://localhost:${authEmuPort}`);
      console.log("Firebase Auth connected to emulator");
    } catch (err) {
      console.log("Cannot connect to Firebase Auth emulator", err);
    }

    // Firestore Emulator
    try {
      connectFirestoreEmulator(
        firestoreDb,
        "localhost",
        process.env.VUE_APP_FIRESTORE_EMULATOR_PORT || 8080
      );
    } catch (err) {
      console.log("Cannot connect to Firebase Cloud Firestore emulator", err);
    }
  }
}

export async function signOut() {
  await firebaseAuth.signOut();
  await FirebaseAuthentication.signOut();
  const userStore = useAuthStore();
  userStore.onUserChange(null);
  user.value = null;
}
export async function signInWithGoogle() {
  const result = await FirebaseAuthentication.signInWithGoogle();
  const credential = GoogleAuthProvider.credential(result.credential?.idToken);
  await signInWithCredential(getAuth(), credential);
  user.value = getAuth().currentUser;
  return user.value;
}
