/* src/stores/team.ts */
import { defineStore } from "pinia";
import { TeamView } from "@/interfaces/teams";
import { getCurrentTeam, setCurrentTeam } from "@/functions/teams";

type CurrentTeam = TeamView | null;

export const useTeamStore = defineStore("team", {
  state: () => ({
    team: null as CurrentTeam,
  }),
  getters: {
    currentTeam: async (state): Promise<CurrentTeam> => {
      const currentTeam = await getCurrentTeam();
      state.team = currentTeam;
      return currentTeam;
    },
  },
  actions: {
    async setTeam(team: TeamView) {
      await setCurrentTeam(team);
      this.team = team;
    },
  },
});
