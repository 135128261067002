/*
 * src/components/plugins/auth.ts
 * Authetication plugin for app
 */
import { App } from "vue-demi";
import { ref, computed } from "vue";
import { User as FirebaseUser, IdTokenResult } from "firebase/auth";
import { firebaseAuth } from "@/firebase.service";
import { onAuthStateChanged, Auth, onIdTokenChanged } from "firebase/auth";

// Current firebase app user
const currentUser = ref<FirebaseUser | null>(null);
// Token result from firebase auth
const idToken = ref<IdTokenResult | null>(null);

const userClaims = computed((): null | Record<string, boolean> => {
  /* User claims if user is logged-in */
  if (idToken.value === null) {
    return null;
  } else {
    return idToken.value.claims;
  }
});

const authToken = computed((): string | null => {
  /* JWT auth token string of user */
  if (idToken.value === null) {
    return null;
  } else {
    return idToken.value.token;
  }
});

const tokenExpirationTime = computed((): string | null => {
  /* JWT auth token expiration time*/
  if (idToken.value === null) {
    return null;
  } else {
    return idToken.value.expirationTime;
  }
});

const userPhotoUrl = computed((): string => {
  /* user photo url with default */
  const defaultUrl = "https://ionicframework.com/docs/img/demos/avatar.svg";
  return currentUser.value?.photoURL ? currentUser.value.photoURL : defaultUrl;
});

export const useAppAuth = () => {
  /* AppAuth reactive module that contains all auth info and provides auth moethds */
  const onAuthChange = async (user: FirebaseUser | null) => {
    /* callback function for user auth changed */
    currentUser.value = user;
    if (user == null) {
      idToken.value = null;
    } else {
      idToken.value = await user.getIdTokenResult(true);
    }
  };

  const getUserClaims = async (): Promise<null | Record<string, boolean>> => {
    /* Get user claims directly from firebase id token result */
    if (currentUser.value === null) {
      return null;
    } else {
      const tokenResult = await currentUser.value.getIdTokenResult(true);
      if (tokenResult) {
        return tokenResult.claims;
      } else {
        return null;
      }
    }
  };

  const refreshAuth = async (): Promise<void> => {
    /* reload current user from firebase */
    const currentUser = firebaseAuth.currentUser;
    await onAuthChange(currentUser);
  };

  const getAuthToken = async (): Promise<string | null> => {
    /* Get JWT (automatically refresh if expired) */
    if (currentUser.value === null) {
      return null;
    }
    if (tokenExpirationTime.value === null) {
      return authToken.value;
    }
    const now = new Date();
    const expirationTime = new Date(tokenExpirationTime.value);
    if (now > expirationTime) {
      await refreshAuth();
    }
    return authToken.value;
  };

  return {
    currentUser,
    onAuthChange,
    userClaims,
    authToken,
    userPhotoUrl,
    getUserClaims,
    refreshAuth,
    getAuthToken,
  };
};

interface AuthPluginOption {
  firebaseAuth: Auth;
}
export const AuthPlugin = {
  install(app: App, options: AuthPluginOption) {
    const { onAuthChange } = useAppAuth();
    onAuthChange(firebaseAuth.currentUser);
    onAuthStateChanged(options.firebaseAuth, onAuthChange);
    onIdTokenChanged(options.firebaseAuth, onAuthChange);
    app.config.globalProperties.$useAuth = useAppAuth;
  },
};
