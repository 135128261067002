/* src/functions/lang.ts */

const LangStore = {} as Record<string, Record<string, any>>;

export function register_lang(
  text: string,
  translation: any,
  text_domain = "default"
): void {
  // LangStore[text_domain][text] = translation;
  const domain = LangStore[text_domain] || {};
  domain[text] = translation;
  LangStore[text_domain] = domain;
}

export function lang(text: string, domain = "default"): any {
  try {
    const translation = LangStore[domain][text];
    if (translation !== undefined) {
      return translation;
    } else {
      return text;
    }
  } catch (_) {
    return text;
  }
}

function register_order_filter(): void {
  const phrases = {
    from_date: "from",
    to_date: "to",
    external_status: "status",
    s: "search for",
  } as Record<string, any>;
  Object.keys(phrases).forEach((key) => {
    register_lang(key, phrases[key], "order_filter");
  });
}

export function register_default(): void {
  const default_lang = {
    address_1: "address 1",
    address_2: "address 2",
    first_name: "first name",
    last_name: "last name",
    USD: "$",
    consumer_key: "Consumer key",
    consumer_secret: "Consumer secret",
    _wc_shipment_tracking_items: "shipment trackings",
    tracking_provider: "tracking provider",
    tracking_number: "tracking number",
    date_shipped: "date shipped",
  } as Record<string, any>;
  Object.keys(default_lang).forEach((key) => {
    register_lang(key, default_lang[key]);
  });
  register_order_filter();
}
